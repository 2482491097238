.react-flow__node-toolbar {
  display: flex;
  gap: 10px;
}

.toolbar_button {
  padding: 3px 3px;
  cursor: pointer;
}

.react-flow__node {
  display: flex;
  justify-content: center;
  border-width: 0;
  font-size: 8px;
  background-color: #435e7a;
  color: white;
  z-index: 1001 !important;
}

.wrapper {
  flex-grow: 1;
  height: 100%;
  width: 100%;
  position: relative;
}

.react-flow__node-input {
  padding: 3px;
}

.settingsRow {
  display: flex;
  width: 580px;
  justify-content: space-between;
  align-items: center;
}

.settingShortRow {
  display: flex;
  width: 220px;
  justify-content: space-between;
  align-items: center;
}

.settingMiddleRow {
  display: flex;
  width: 380px;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.settingsSubBlock {
  display: flex;
  gap: 10px;
}

.tableLine {
  display: flex;
  cursor: pointer;
  height: 20px;
  padding: 5px 0;
}

.tableLine:hover {
  background-color: #305478;
  color: white;
}

.tableLine_active {
  background-color: #305478;
  color: white;
  display: flex;
  cursor: pointer;
  height: 20px;
  padding: 5px 0;
}

.signalWrapper {
  display: flex;
  justify-content: center;
}
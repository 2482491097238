* {
  font-family: "Comfortaa", serif;
  font-optical-sizing: auto;
  /* font-weight: <weight>; */
  font-style: normal;
}

body {
  margin: 0;
}

.upload-container {
  width: 300px;
  height: 240px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  position: relative;
}

.upload-icon {
  font-size: 24px;
  margin-bottom: 10px;
}

.upload-text {
  font-size: 14px;
  color: #333;
}

.image-preview {
  margin-top: 20px;
}

.image-preview img {
  max-width: 100%;
  max-height: 100%;
}
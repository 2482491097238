* {
    margin: 0;
    padding: 0;
}
a:active {
    text-decoration: none;
}
.header {
    height: 48px;
    background-color: #305478;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
    border-bottom: 2px solid gray; 
}
.header_logo {
    width: 100px;
}
.support {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.support:hover {
    background-color: green;
}
.main_block {
    display: flex;
    justify-content: center;
    gap: 50px;
    background-color: #FCFCFC;
    height: 35vh;
    align-items: center;
    padding: 20px 0;
    flex-wrap: wrap;
    background-image: url('../public/images/bg.png');
    color: #305478;
}
.main_block_login {
    display: flex;
    justify-content: center;
    background-color: #FCFCFC;
    height: calc(100vh - 110px);
    align-items: center;
    background-image: url('../public/images/bg.png');
}
.login_form {
    width: 450px;
    height: 270px;
    border-radius: 5%;
    background-color:#305478;
}
.reg_form {
    width: 450px;
    max-height: 370px;
    border-radius: 5%;
    background-color:#305478;
    padding: 15px 0;
}
.main_text {
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 400px;
    width: 100%;
}
.main_bot {
    width: 300px;
}
.block {
    width: 100%;
    padding: 25px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    text-align: center;
}
.subtitle {
    font-size: 20px;
}
.list_item {
    margin: 5px 0;
    font-size: 20px;
}
.column_list {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
}
.column_list_item {
    text-align: center;
    padding: 5px 10px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.table_item {
    border: 2px solid #305478;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #305478;
}
.sideMenu {
    width: 100%;
    max-width: 200px;
    background-color: #305478;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    height: calc(100vh - 100px);
    overflow-y: scroll;
}
.sideMenu::-webkit-scrollbar {
    width: 0px;
}
.workArea {
    width: 100%;
    height: calc(100vh - 60px);
    overflow-y: scroll;
}
.workArea::-webkit-scrollbar {
    width: 8px;
}
.tgContainer {
    width: 100%;
    max-width: 250px;
    background-color: #305478;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: calc(100vh - 60px);
}
.tgButton {
    display: flex;
    color: white;
    border-top: 1px solid white;
    cursor: pointer;
    padding: 10px 0;
    width: 100%;
    justify-content: center;
    font-weight: bold;
}
.tgArea {
    height: 100%;
    width: 95%;
    display: flex;
    flex-direction: column-reverse;
    gap: 6px;
    padding: 20px 10px;
    overflow-y: scroll;
    font-size: 13px;
}
.tgArea::-webkit-scrollbar {
    width: 8px;
}
.tgMessage {
    padding: 5px 5px;
    background-color: #fff;
    max-width: 230px;
    width: 100%;
    min-height: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.gameWorkArea {
    height: calc(100vh - 190px);
    display: flex;
    align-items: center;
    padding-left: 20px;
}
.footer {
    height: 50px;
    background-color: #305478;
}